import { matchRoutes, useLocation } from 'react-router-dom';

const routes = [
  { id: 'activity', path: '/activity' },
  { id: 'activity-tv', path: '/activity/tv-mode' },
  { id: 'employee-level', path: '/activity/employee-level' },
];

export const useCurrentPath = (): { id: string; path: string } => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location) || [];

  return route;
};
